import { CheckBox } from '@ui/components/checkbox-input';
import { container } from '@ui/components/container/index.css';
import { Flex } from '@ui/components/flex';
import { Text } from '@ui/components/text';
import { type Validation as ValidationT } from 'packages/types/src';
import { Controller, type Control, useWatch } from 'react-hook-form';

type CheckBoxProps = {
  control: Control;
  // items?: { title: string; hint?: string }[];
  items?: {
    id: string;
    value: string;
  }[];
  groupName: string;
  optional: boolean;
  name: string;
  generateRequiredRule: ValidationT.GenerateRequiredRule;
};

export const FormCheckBox = ({ control, name, items, groupName, optional, generateRequiredRule }: CheckBoxProps) => {
  const watchCheckboxItem = items?.map((item) =>
    useWatch({
      control,
      name: `${name} ${item.id}`,
    })
  );
  const isAnyItemChecked = watchCheckboxItem?.some((value) => value);

  return (
    <Flex className={container} direction="column">
      <Flex justifyContent="space-between">
        {groupName && (
          <Text aria-label={groupName} as="h4" color="contrast" marginBottom="space-d" style="body1" weight="bold">
            {groupName}
          </Text>
        )}
        {optional ? (
          <Text color="contrast" style="body2" textAlign="right">
            {'Optional'}
          </Text>
        ) : (
          !isAnyItemChecked && (
            <Text color="error" style="body3">
              {'Required'}
            </Text>
          )
        )}
      </Flex>
      <Flex direction="column" gap="space-d">
        {items &&
          items.map((item) => {
            return (
              <Controller
                control={control}
                defaultValue={false}
                key={name}
                name={`${name} ${item.id}`}
                render={({ field, fieldState: { error } }) => (
                  <CheckBox
                    checked={!!field.value}
                    disabled={field.disabled}
                    error={!!error}
                    errorMessage={error?.message}
                    // hint={item.hint}
                    label={item.value}
                    onChange={field.onChange}
                  />
                )}
                rules={generateRequiredRule(!optional)}
              />
            );
          })}
      </Flex>
    </Flex>
  );
};
